import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import "../../components/Product/styles.scss";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SEO from "../../components/Seo";
import Product from "../../components/ProductV2";
import { ImageInterface } from "../../types/SanityTypes";
import { event8_prodView } from "../../analytics/event8_prodView";
export const query = graphql`
  query ($_id: String, $language: String, $market: String) {
    sanityProductV2(_id: { eq: $_id }) {
      _id
      subBrand {
        name
        format
      }
      variant {
        variantOrderSeq
        eVariantName
        fragrance
      }
      _rawDescription
      paBrandName
      _rawHowToUse
      _rawImages(resolveReferences: { maxDepth: 8 })
      _rawIngredients
      _rawPerfectFor
      _rawTryThis
      displayReviews
      name
      seoShortTitle
      seoShortDescription
      shortDescription
      paSmartLabelId
      paSmartProductId
      smartProductId
      paSize
      paBrandCode
      paRetailerCategory
      slug {
        current
      }
      tags {
        tagName
        tagCategory
        _rawImage(resolveReferences: { maxDepth: 8 })
      }
      paUPC
      promoVideo {
        _rawCoverImage(resolveReferences: { maxDepth: 8 })
        _rawText(resolveReferences: { maxDepth: 8 })
        video {
          asset {
            url
          }
        }
        colorlist {
          title
          value
        }
        transcript {
          asset {
            url
          }
        }
        videoType {
          name
        }
        _id
        _type
      }
      videos {
        _type
        video {
          asset {
            url
          }
        }
        coverImage {
          _key
          _type
          alt
          asset {
            url
          }
          crop {
            top
            right
            left
            bottom
          }
        }
      }
    }
    Kritique: allSanityRatingReviewsConfig {
      nodes {
        ratingReviewsData {
          kritiqueApiUrl
        }
      }
    }
    ...LocalizedContext
  }
`;

const ProductPage = (props: any) => {
  const language = props.pageContext.language;
  const productSubBrandReferences = props.pageContext.subBrand;
  const productGoesWellWith = props.pageContext.productGoesWellWith;
  const product = props.data.sanityProductV2;
  const productLandingPage = props.data.sanityProductLandingPage;
  const productLabels = props.data.sanityLabels?.productLabels;
  const productData = {
    ...product,
    productLandingPage
  };
  const { htmlLang, prmBrandCode, locale, kritiqueApiKey } = useSiteMetadata(language);
  const { kritiqueApiUrl } = props.data.Kritique.nodes[0]?.ratingReviewsData;

  const image =
    (product._rawImages as ImageInterface[])?.length > 0 ? (product._rawImages as ImageInterface[])[0] : null;
  const imageUrl = image?.asset?.url ? { url: image?.asset.url as string } : undefined;

  const pageTitle = product.name;
  const kritiqueLocale = locale.toLowerCase().replace(/_/g, "-");
  const productId = product.paUPC;
  const parseKritiqueApiKey = kritiqueApiKey.toUpperCase();

  const [reviewsSEO, setReviewsSEO] = useState<string>();
  const reviewsSEOUrl = `${kritiqueApiUrl}reviews/${prmBrandCode}/${kritiqueLocale}/${productId}/seo?page=0&size=1000&oldformat=false`;

  useEffect(() => {
    event8_prodView(product._id, product.name, props.data?.sanitySiteSettings?.brandName);
  }, [product._id, product.name, props.data?.sanitySiteSettings?.brandName]);

  useEffect(() => {
    if (!window || !kritiqueApiUrl) return;

    fetch(reviewsSEOUrl, {
      method: "GET",
      headers: {
        "x-api-key": parseKritiqueApiKey
      }
    })
      .then(res => res.text())
      .then(body => setReviewsSEO(body))
      .catch(e => console.log(e));
  }, [reviewsSEOUrl]);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"product"}
      parentPages={["Products"]}
      cssClass={"product-detail-v2"}
      language={language}
    >
      {reviewsSEO && (
        <div
          className="kritique-seo"
          style={{
            display: "none"
          }}
          dangerouslySetInnerHTML={{
            __html: reviewsSEO
          }}
        />
      )}
      <SEO
        title={product.seoShortTitle}
        description={product.seoShortDescription}
        pageType={"product"}
        imageUrl={imageUrl?.url}
        imageAlt={image?.alt}
      />
      <Product
        sanityProductV2={product}
        sanityProductLandingPage={productLandingPage}
        productLabels={productLabels}
        references={productSubBrandReferences}
        productGoesWellWith={productGoesWellWith}
        language={language}
      />
    </Layout>
  );
};

export default ProductPage;
